import React, { useState } from 'react';
import {
    Tournament,
    AdditionalResource,
    SelectedEvent
} from './components/types';
import { TournamentHeader } from './components/TournamentHeader';
import { LocationSection } from './components/LocationSection';
import { AdditionalResourcesSection } from './components/AdditionalResourcesSection';
import { PersonalInformationForm } from './components/PersonalInformationForm';
import { GuardianInformationForm } from './components/GuardianInformationForm';
import { EventSelectionForm } from './components/EventSelectionForm';
import { PriceBreakdown } from './components/PriceBreakdown';
import LoadingPopup from '../../components/LoadingPopup';

interface TournamentRegistrationProps {
    tournament: Tournament;
    onSubmit: (formData: FormData) => Promise<void>;
    onEventsChange: (selectedEvents: SelectedEvent[]) => void;
    onMembershipChange: (formData: FormData) => void;
    bannerImage?: string;
    isLoading?: boolean;
    additionalResources?: AdditionalResource[];
    priceBreakdown: any;
    selectedEvents: SelectedEvent[];
    hasMemberDiscounts: boolean;
}

interface FormData {
    preferredFirstName: string;
    preferredLastName: string;
    legalFirstName: string;
    legalLastName: string;
    email: string;
    phoneNumber: string;
    clubAffiliation: string;
    selectedEvents: SelectedEvent[];
    isGuardian: boolean;
    guardianFirstName: string;
    guardianLastName: string;
    isCUFCMember: boolean;
}

type FormErrors = {
    [key: string]: string | undefined;
}

const TournamentRegistration: React.FC<TournamentRegistrationProps> = ({
    tournament,
    onSubmit,
    onEventsChange,
    onMembershipChange,
    bannerImage,
    isLoading = false,
    additionalResources,
    priceBreakdown,
    selectedEvents,
    hasMemberDiscounts,
}) => {
    const [formData, setFormData] = useState<FormData>({
        preferredFirstName: '',
        preferredLastName: '',
        legalFirstName: '',
        legalLastName: '',
        email: '',
        phoneNumber: '',
        clubAffiliation: '',
        selectedEvents,
        isGuardian: false,
        guardianFirstName: '',
        guardianLastName: '',
        isCUFCMember: false,
    });

    const [errors, setErrors] = useState<FormErrors>({});
    const [touched, setTouched] = useState<Record<string, boolean>>({});

    const validateField = (name: keyof FormData, value: any): string | undefined => {
        if (!value && typeof value !== 'boolean') {
            return `${name} is required`;
        }

        switch (name) {
            case 'email':
                if (!/\S+@\S+\.\S+/.test(value)) {
                    return 'Please enter a valid email address';
                }
                break;
            case 'selectedEvents':
                if (value.length === 0) {
                    return 'Please select at least one event';
                }
                break;
            case 'guardianFirstName':
            case 'guardianLastName':
                if (formData.isGuardian && !value) {
                    return `Guardian ${name.replace('guardian', '').toLowerCase()} name is required`;
                }
                break;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        
        setFormData(prev => {
            const newFormData = { ...prev, [name]: newValue };
            
            if (name === 'isCUFCMember') {
                onMembershipChange(newFormData);
            }
            
            if (name === 'isGuardian' && !checked) {
                return {
                    ...newFormData,
                    guardianFirstName: '',
                    guardianLastName: ''
                };
            }
            
            return newFormData;
        });

        if (touched[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: validateField(name as keyof FormData, newValue)
            }));
        }
    };

    const handleEventSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name: eventId } = e.target;
        const eventName = tournament.events.find(e => e._id === eventId)?.name || '';

        const updatedEvents = checked
            ? [...selectedEvents, { id: eventId, name: eventName }]
            : selectedEvents.filter(e => e.id !== eventId);

        setFormData(prev => ({ ...prev, selectedEvents: updatedEvents }));
        onEventsChange(updatedEvents);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTouched(prev => ({ ...prev, [name]: true }));
        setErrors(prev => ({
            ...prev,
            [name]: validateField(name as keyof FormData, value)
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        const requiredFields = [
            'selectedEvents',
            'preferredFirstName',
            'preferredLastName',
            'legalFirstName',
            'legalLastName',
            'email',
            'phoneNumber',
            ...(formData.isGuardian ? ['guardianFirstName', 'guardianLastName'] : [])
        ];

        const newErrors: FormErrors = {};
        requiredFields.forEach(field => {
            const error = validateField(field as keyof FormData, formData[field as keyof FormData]);
            if (error) newErrors[field] = error;
        });

        setErrors(newErrors);
        
        if (Object.keys(newErrors).length === 0) {
            await onSubmit(formData);
        }
    };

    return (
        <>
            {isLoading && <LoadingPopup isOpen={isLoading} message="Processing..." />}
            <form onSubmit={handleSubmit} className="space-y-8">
                <div className="min-h-screen bg-gray-50 py-8">
                    <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm px-4 py-8 sm:px-6 lg:px-8">
                        <TournamentHeader
                            tournament={tournament}
                            bannerImage={bannerImage}
                        />
                        
                        <div className="max-w-4xl mx-auto p-4 md:p-6">
                            <LocationSection location={tournament.location} />
                            
                            {additionalResources && (
                                <AdditionalResourcesSection resources={additionalResources} />
                            )}
                            
                            <EventSelectionForm
                                tournament={tournament}
                                selectedEvents={selectedEvents}
                                errors={{ selectedEvents: errors.selectedEvents }}
                                touched={{ selectedEvents: touched.selectedEvents }}
                                onEventSelection={handleEventSelection}
                            />
                            
                            <div className="mt-12">
                                <PersonalInformationForm
                                    formData={formData}
                                    errors={errors}
                                    touched={touched}
                                    onInputChange={handleInputChange}
                                    onBlur={handleBlur}
                                    hasMemberDiscounts={hasMemberDiscounts}
                                />
                            </div>
                            
                            {formData.isGuardian && (
                                <GuardianInformationForm
                                    formData={formData}
                                    errors={errors}
                                    touched={touched}
                                    onInputChange={handleInputChange}
                                    onBlur={handleBlur}
                                />
                            )}
                            
                            <PriceBreakdown {...priceBreakdown} />
                            
                            <button
                                type="submit"
                                disabled={isLoading || (Object.keys(errors).length > 0 && Object.values(errors).some(error => error !== undefined))}
                                className={`w-full mt-6 py-4 px-8 md:rounded-lg text-center font-semibold transition-all ${
                                    isLoading || (Object.keys(errors).length > 0 && Object.values(errors).some(error => error !== undefined))
                                        ? 'bg-MediumPink cursor-not-allowed'
                                        : 'bg-Navy text-white hover:bg-MediumPink'
                                }`}
                            >
                                {isLoading ? 'Submitting...' : 'Register'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default TournamentRegistration;
