import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { MemberStats, LoadingPopup, AdminMembers } from 'cufc-ui'
import { MemberModel } from '../../models/Member'
import {
  deleteMemberAPI,
  getMembersAPI,
  updateMemberAPI,
  getDailyAttendanceAggregateAPI,
  addMemberAPI,
  getEmailListsAPI,
  sendEmailToListAPI,
} from '../../services/api'



const AdminMembersView: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [members, setMembers] = useState<MemberModel[]>([])
  const [isLoading, setLoading] = useState(true)

  const getMembers = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const response = await getMembersAPI(accessToken)
      if (response.data) {
        setMembers(response.data)
      }
    } catch (error) {
      console.error('Failed to fetch members:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getMembers()
    }
    fetchData()
  }, [getAccessTokenSilently])

  const isWithinLastTwoMonths = (date) => {
    if (!date) return false;
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    return new Date(date) > twoMonthsAgo;
  };

  const stats = {
    total: members.length,
    active: members.filter(m => m.subscription_status?.toLowerCase() === 'active').length,
    coaches: members.filter(m => m.role === 'coach').length,
    checkedIn: members.filter(m => m.checkedIn === true).length,
    recentlyCheckedIn: members.filter(m => isWithinLastTwoMonths(m.lastCheckInDate)).length
  };

  const onUpdateMember = async (updatedMemberData: MemberModel) => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently()
    await updateMemberAPI(updatedMemberData, accessToken)
    await getMembers()  
  }

  const onDeleteMember = async (deletedMemberId: string) => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently()
    await deleteMemberAPI(deletedMemberId, accessToken)
    await getMembers()
  }

  const onAddMember = async (updatedMemberData: MemberModel) => {
    setLoading(true)
    console.log(updatedMemberData)
    const accessToken = await getAccessTokenSilently()
    await addMemberAPI(updatedMemberData, accessToken)
    await getMembers()
  }


  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mt-4">
      {isLoading ? (
        <LoadingPopup isOpen={isLoading} message="Loading information..." />
      ) : (
        <div>
        <MemberStats stats={stats} />
        <AdminMembers
        members={members}
        onUpdateMember={onUpdateMember}
        onDeleteMember={onDeleteMember}
        onAddMember={onAddMember} />
        </div>
      )}
    </div>
  );
};

export default AdminMembersView;
