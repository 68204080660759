import React, { useState, useEffect } from 'react'
import { AdminNavigation } from 'cufc-ui'
import AdminMembersView from './AdminMembersView'
import AdminAttendanceView from './AdminAttendanceView'
import AdminEmailsView from './AdminEmailsView'
import { useAuth0 } from '@auth0/auth0-react'

const NewAdminView: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Members')
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const [hasAdminPermission, setHasAdminPermission] = useState(false)

  useEffect(() => {
    const checkAdminPermission = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently()
          const tokenPayload = JSON.parse(atob(token.split('.')[1]))
          const permissions = tokenPayload.permissions || []
          setHasAdminPermission(permissions.includes('club-admin'))
        } catch (error) {
          console.error('Error checking admin permissions:', error)
          setHasAdminPermission(false)
        }
      }
    }
    
    checkAdminPermission()
  }, [isAuthenticated, user, getAccessTokenSilently])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-sm max-w-md w-full">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Authentication Required</h2>
          <p className="text-gray-600">
            Please sign in to access the admin dashboard.
          </p>
        </div>
      </div>
    )
  }

  if (!hasAdminPermission) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-sm max-w-md w-full">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Access Denied</h2>
          <p className="text-gray-600">
            You do not have sufficient privileges to access this page. Please contact an administrator if you believe this is an error.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div>
          <AdminNavigation 
            activeTab={activeTab}
            onTabChange={onTabChange}
          />
          
          <div id="Members" style={{ display: activeTab === 'Members' ? 'block' : 'none' }}>
            <AdminMembersView />
          </div>
          
          <div id="Attendance" style={{ display: activeTab === 'Attendance' ? 'block' : 'none' }}>
            <AdminAttendanceView />
          </div>
          
          <div id="Email" style={{ display: activeTab === 'Email' ? 'block' : 'none' }}>
            <AdminEmailsView />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAdminView;