import { useState } from 'react'
import { SmallHero } from 'cufc-ui'
import { NewMemberSignUp } from 'cufc-ui'

const apiURL = process.env.REACT_APP_API_SERVER_URL

const NewMemberSignUpPage = () => {
  const [emailStatusMessage, setEmailStatusMessage] = useState('')

  const onSubmitNewStudentForm = async (formData) => {
    try {
      setEmailStatusMessage('Submitting...')
      await submitEmail(formData)
      const numFamilyMembers = formData.additionalFamilyMembers.length

      switch (formData.requestedMembershipType) {
        case 'fullMembership':
          window.location.href =
            'https://checkout.square.site/merchant/MLKW7ZG52B9ZV/checkout/HYUNGQWY7OLVCJMHDOE3DDMY'
          break
        case 'socialMembership':
          window.location.href =
            'https://checkout.square.site/merchant/MLKW7ZG52B9ZV/checkout/LTON5IQZMMD53XMXJCE5NTV7'
          break
        case 'familyPlan':
          if (numFamilyMembers === 1) {
            window.location.href =
              'https://checkout.square.site/merchant/MLKW7ZG52B9ZV/checkout/JV6FNZ3XNCOALKY33QFS345D'
          } else if (numFamilyMembers === 2) {
            window.location.href =
              'https://checkout.square.site/merchant/MLKW7ZG52B9ZV/checkout/KHIZ5SX32SL22FREWEVB5SCH'
          } else {
            setEmailStatusMessage('Email sent! We will reach out to you. :)')
          }
          break
        case 'nugget':
          window.location.href =
            'https://checkout.square.site/merchant/MLKW7ZG52B9ZV/checkout/ZDWGICM52N6C6Y2ITCWJJLZR'
          break
        case 'dropIn':
          setEmailStatusMessage(
            'Thank you! Use the drop in button when you visit.',
          )
          break
        default:
          setEmailStatusMessage('Email sent! We will reach out to you. :)')
          break
      }
    } catch (error) {
      setEmailStatusMessage('Error Sending Email. :( Tell Steph.')
      console.error('Error sending email:', error)
    }
  }

  const submitEmail = async (formData) => {
    console.log(formData)
    try {
      const response = await fetch(apiURL + '/api/email/sendNewMemberEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (!response.ok) {
        throw new Error(`Failed to send email: ${response.statusText}`)
      }
      console.log('Email sent successfully!')
    } catch (error: any) {
      throw new Error(`Error sending email: ${error.message}`)
    }
  }

  return (
    <>
      <SmallHero pageTitle="" />
      <NewMemberSignUp
        onSubmit={onSubmitNewStudentForm}
        emailStatusMessage={emailStatusMessage}
      />
    </>
  )
}

export default NewMemberSignUpPage
