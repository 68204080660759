import 'cufc-ui/styles/output.css'
import { Footer, Nav } from 'cufc-ui'
import { Route, Routes, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import HomeView from './views/homeview/HomeView'
import CheckInView from './views/checkin/CheckInView'
import NewMemberSignUpPage from './views/newmembersignup/NewMemberSignUpPage'
import { useAuth0 } from '@auth0/auth0-react'
import ContactComponent from './views/contact/ContactComponent'
import ClassesView from './views/classes/ClassesView'
import AboutUsComponent from './views/about/AboutUsComponent'
import WorkshopRegistrationView from './views/events/WorkshopRegistrationView'
import IntroClassComponent from './views/introclass/IntroClassComponent'
import UnsubscribeView from './views/unsubscribe/UnsubscribeView'
import ScrollToTop from './components/ScrollToTop'
import {
  instagramLink,
  facebookLink,
  squareDonationLink,
  squareDropInLink,
} from './constants'
import NewAdminView from './views/newadmin/NewAdminView'
import TournamentRegistrationView from './views/events/TournamentRegistrationView'

const App = () => {
  const navigate = useNavigate()
  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0()
  const [userPermissions, setUserPermissions] = useState<string[]>([])

  useEffect(() => {
    const loadScriptWithNonce = () => {
      const nonce = (window as any).NONCE
      const script = document.createElement('script')
      script.src =
        'https://www.google.com/recaptcha/api.js?onload=onloadcallback&render=explicit'
      script.async = true
      script.defer = true
      script.nonce = nonce
      document.body.appendChild(script)
    }

    loadScriptWithNonce()

    if (isAuthenticated && user) {
      setUserInfo()
    }
  }, [isAuthenticated, user])

  const handleOnNavigationClick = (endpoint: string) => {
    navigate('/' + endpoint)
  }

  const setUserInfo = async () => {
    try {
      const token = await getAccessTokenSilently()
      const tokenPayload = JSON.parse(atob(token.split('.')[1]))
      const permissions = tokenPayload.permissions || []
      setUserPermissions(permissions)
    } catch (error) {
      console.error('Error fetching user info:', error)
    }
  }

  const handleSignInSignOut = () => {
    isAuthenticated ? signOut() : signIn()
  }

  const signOut = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  const signIn = async () => {
    await loginWithRedirect({
      appState: { returnTo: window.location.pathname }
    })
  }

  const DonationRedirect = () => {
    useEffect(() => {
      window.location.href = squareDonationLink
    }, [])
    return null
  }

  const DropInRedirect = () => {
    useEffect(() => {
      window.location.href = squareDropInLink
    }, [])
    return null
  }

  return (
    <div className="relative min-h-screen">
      <div className="relative z-10">
        <ScrollToTop />
        <Nav
          onNavigationClick={handleOnNavigationClick}
          handleAuth={handleSignInSignOut}
          isAuthenticated={isAuthenticated}
          userProfilePic={user?.picture}
          isAdmin={userPermissions.includes('club-admin')}
        />
        <Routes>
          <Route
            path="/"
            element={<HomeView onNavigationClick={handleOnNavigationClick} />}
          />
          <Route
            path="/admin"
            element={<NewAdminView />}
          />
          <Route path="/joinNow" element={<NewMemberSignUpPage />} />
          <Route path="/donate" element={<DonationRedirect />} />
          <Route path="/dropin" element={<DropInRedirect />} />
          <Route path="/contact" element={<ContactComponent />} />
          <Route path="/checkin" element={<CheckInView />} />
          <Route path="/dashboard" element={<div>Under Construction</div>} />
          <Route path="/events" element={<TournamentRegistrationView />} />
          <Route
            path="/intro"
            element={
              <IntroClassComponent
                onNavigationClick={handleOnNavigationClick}
              />
            }
          />
          <Route
            path="/fundamentals"
            element={
              <IntroClassComponent
                onNavigationClick={handleOnNavigationClick}
              />
            }
          />
          <Route path="/lynxcup" element={<TournamentRegistrationView />} />
          <Route path="/workshop" element={<WorkshopRegistrationView />} />
          <Route
            path="/classes"
            element={
              <ClassesView onNavigationClick={handleOnNavigationClick} />
            }
          />
          <Route path="/unsubscribe" element={<UnsubscribeView />} />
          <Route
            path="/about"
            element={
              <AboutUsComponent onNavigationClick={handleOnNavigationClick} />
            }
          />
        </Routes>
        <Footer instagramLink={instagramLink} facebookLink={facebookLink} />
      </div>
    </div>
  )
}

export default App
