import React, { useEffect, useState } from 'react'
import { AboutNugget } from 'cufc-ui';
import { addEmailToPromotionalList, getUpcomingStartDatesAPI } from '../../services/api';

interface IntroClassComponentProps {
  onNavigationClick: (page: string) => void;
}

interface UpcomingStartDate {
  startDate: string;
  schedule: string;
  meetingTime: string;
  courseTitle: string;
}

const IntroClassComponent: React.FC<IntroClassComponentProps> = ({ onNavigationClick }) => {

  const [upcomingStartDates, setUpcomingStartDates] = useState<UpcomingStartDate[]>([])
  useEffect(() => {

    const fetchUpcomingStartDates = async () => {
      try {
        const data = await getUpcomingStartDatesAPI()
        if (data) {
          setUpcomingStartDates(data)
        }
      } catch (error) {
        console.error('Error fetching schedule data:', error)
      }
    }

    fetchUpcomingStartDates()
  }, [])


  const handleEmailSignup = async (email: string) => {
    try {
      await addEmailToPromotionalList(email);
    } catch (error) {
      console.error('Error adding email to promotional list:', error);
    }
  };

  return (
    <div>
      <AboutNugget 
        onNavigationClick={onNavigationClick} 
        onEmailSignup={handleEmailSignup}
        upcomingDates={upcomingStartDates }
      />
    </div>
  );
};

export default IntroClassComponent;
