import React from 'react';
import { AttendanceGraph } from 'cufc-ui'
import { useEffect, useState } from 'react'
import { getDailyAttendanceAggregateAPI } from '../../services/api'
import { DailyAttendanceAggregateCount } from '../../models/AttendanceAggregate'

const AdminAttendance: React.FC = () => {
  const [dailyAttendanceAggregateData, setDailyAttendanceAggregateData] =
    useState<DailyAttendanceAggregateCount[]>([])

    const getDailyAggregateAttendanceData = async () => {
      try {
        const res = await getDailyAttendanceAggregateAPI()
        if (res.data) {
          setDailyAttendanceAggregateData(res.data)
        }
      } catch (error) {
        console.error('Error fetching attendance:', error)
      }
    }  

  useEffect(() => {
    getDailyAggregateAttendanceData()
  }, [])

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mt-4">
      <AttendanceGraph data={dailyAttendanceAggregateData} />
    </div>
  );
};

export default AdminAttendance;
