import { useEffect, useState } from 'react'
import { EmailSender } from 'cufc-ui'
import { useAuth0 } from '@auth0/auth0-react'
import {
  getEmailListsAPI,
  sendEmailToListAPI,
  getAllMemberEmailsListAPI,
} from '../../services/api'

const AdminEmail: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [emailLists, setEmailLists] = useState<Array<{
    id: string;
    name: string;
    emails: string[];
  }>>([])

  const getEmailLists = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const res = await getEmailListsAPI(accessToken)
      const memberListRes = await getAllMemberEmailsListAPI(accessToken)
      if (res.data) {
        console.log('getEmailLists', res.data)
        setEmailLists([...res.data, memberListRes.data])
      }
    } catch (error) {
      console.error('Error fetching email lists:', error)
    }
  }

  const handleOnSendEmail = async (emailData) => {
    try {
      const accessToken = await getAccessTokenSilently()
      const response = await sendEmailToListAPI(emailData, accessToken)
      console.log('Email sent successfully!', response.data)
      return response.data
    } catch (error) {
      console.error('Error sending email:', error)
      throw error
    }
  }

  useEffect(() => {
    getEmailLists()
  }, [])

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mt-4">
      <EmailSender recipientLists={emailLists} onSend={handleOnSendEmail}/>
    </div>
  );
};

export default AdminEmail;
