import React, { useEffect, useState } from 'react'
import { Schedule } from 'cufc-ui'
import { useAuth0 } from '@auth0/auth0-react'
import { 
  getScheduleDataAPI, 
  addScheduleItemAPI,
  updateScheduleItemAPI,
  deleteScheduleItemAPI,
  addEventAPI,
  updateEventAPI,
  deleteEventAPI,
  addClosureAPI,
  updateClosureAPI,
  deleteClosureAPI
} from '../services/api'
import { ScheduleItem, Closure, Event } from '../models/ScheduleTypes'

interface ScheduleComponentProps {
  isAdmin: boolean;
}

const ScheduleComponent: React.FC<ScheduleComponentProps> = ({ isAdmin }) => {
  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([])
  const [upcomingClosures, setUpcomingClosures] = useState<Closure[]>([])
  const [upcomingEvents, setUpcomingEvents] = useState<Event[]>([])
  const [userPermissions, setUserPermissions] = useState<string[]>([])
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && user) {
      setUserInfo()
    }
    fetchScheduleData()
  }, [isAuthenticated, user])

  const setUserInfo = async () => {
    try {
      const token = await getAccessTokenSilently()
      const tokenPayload = JSON.parse(atob(token.split('.')[1]))
      const permissions = tokenPayload.permissions || []
      setUserPermissions(permissions)
    } catch (error) {
      console.error('Error fetching user info:', error)
    }
  }

  const fetchScheduleData = async () => {
    try {
      const { data } = await getScheduleDataAPI()
      if (data) {
        setScheduleItems(data.scheduleItems)
        setUpcomingClosures(data.upcomingClosures)
        setUpcomingEvents(data.upcomingEvents)
      }
    } catch (error) {
      console.error('Error fetching schedule data:', error)
    }
  }

  // Handler functions for schedule items
  const handleAddScheduleItem = async (item: ScheduleItem) => {
    try {
      const token = await getAccessTokenSilently()
      await addScheduleItemAPI(item, token)
      fetchScheduleData() // Refresh data after adding
    } catch (error) {
      console.error('Error adding schedule item:', error)
    }
  }

  const handleUpdateScheduleItem = async (item: ScheduleItem) => {
    try {
      const token = await getAccessTokenSilently()
      await updateScheduleItemAPI(item, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error updating schedule item:', error)
    }
  }

  const handleDeleteScheduleItem = async (item: ScheduleItem) => {
    try {
      const token = await getAccessTokenSilently()
      await deleteScheduleItemAPI(item._id, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error deleting schedule item:', error)
    }
  }

  // Handler functions for events
  const handleAddEvent = async (event: Event) => {
    try {
      const token = await getAccessTokenSilently()
      await addEventAPI(event, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error adding event:', error)
    }
  }

  const handleUpdateEvent = async (event: Event) => {
    try {
      const token = await getAccessTokenSilently()
      await updateEventAPI(event, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error updating event:', error)
    }
  }

  const handleDeleteEvent = async (event: Event) => {
    try {
      const token = await getAccessTokenSilently()
      await deleteEventAPI(event._id, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error deleting event:', error)
    }
  }

  // Handler functions for closures
  const handleAddClosure = async (closure: Closure) => {
    try {
      const token = await getAccessTokenSilently()
      await addClosureAPI(closure, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error adding closure:', error)
    }
  }

  const handleUpdateClosure = async (closure: Closure) => {
    try {
      const token = await getAccessTokenSilently()
      await updateClosureAPI(closure, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error updating closure:', error)
    }
  }

  const handleDeleteClosure = async (closure: Closure) => {
    try {
      const token = await getAccessTokenSilently()
      await deleteClosureAPI(closure._id, token)
      fetchScheduleData()
    } catch (error) {
      console.error('Error deleting closure:', error)
    }
  }

  return (
    <Schedule 
      scheduleItems={scheduleItems} 
      upcomingClosures={upcomingClosures} 
      upcomingEvents={upcomingEvents} 
      isAdmin={isAdmin}
      onAddScheduleItem={handleAddScheduleItem}
      onEditScheduleItem={handleUpdateScheduleItem}
      onDeleteScheduleItem={handleDeleteScheduleItem}
      onAddEvent={handleAddEvent}
      onEditEvent={handleUpdateEvent}
      onDeleteEvent={handleDeleteEvent}
      onAddClosure={handleAddClosure}
      onEditClosure={handleUpdateClosure}
      onDeleteClosure={handleDeleteClosure}
    />
  )
}

export default ScheduleComponent
